import Settings from "./settings"
import qs from "qs"

export function getPublicPageUrl(name) {
  if (!name) {
    return Settings.DATENO_FREE_SEARCH_URL
  }
  return `${Settings.DATENO_FREE_SEARCH_URL}/${name}/`
}

export function getSearchUrl(queryString) {
  const params = queryString ? "?" + queryString : ""
  return getPublicPageUrl("search") + params
}

export function getPrivacyUrl() {
  return getPublicPageUrl("privacy")
}

export function getApiDocsUrl() {
  return Settings.DATENO_PUBLIC_API_URL
}

const SOCIAL_LINKS = Object.freeze({
  github: "https://github.com/datenoio",
  twitter: "https://x.com/datenoproject",
  telegram: "https://t.me/datenosearch",
  discord: "https://discord.gg/tydNfp5EY8",
  medium: "https://medium.com/dateno/about",
})

export function getSocialUrl(app) {
  return SOCIAL_LINKS[app.toLowerCase()]
}

export function queryToObject(query) {
  // Must use the same parser as in instantsearch getRouting (see dateno-frontend)
  return qs.parse(query)
}
